.ct__featured-article__article {
	min-height: 93vh;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	position: relative;
}

.ct__featured-article__article img {
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	object-fit: cover;
	position: absolute;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.ct__featured-article__article-components {
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 2rem;
	height: 100%;
}
.ct__featured-article__article-components p {
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 12px;
	line-height: 35px;
	color: #ffffff;
	filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.45));
	padding-left: 0.3rem;
}
.ct__featured-article__article-components h1 {
	font-weight: 900;
	font-size: 85px;
	line-height: 82px;
	letter-spacing: -2px;
	color: #ffffff;
	font-family: var(--font-family);
	margin-top: 0.5rem;
	max-width: 70%;
	filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.45));
	padding-bottom: 2rem;
}
.ct__featured-article__article-components-ba {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.ct__featured-article__article-components-ba button {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	cursor: pointer;
	background: transparent;
	border: 2px solid white;
	color: white;
	padding: 0.7rem 1rem;
	border-radius: 1.5rem;
	filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.65));
	font-size: 14px;
}
.ct__featured-article__article-components-article {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-radius: 1.5rem;
	border: none;
	background-color: rgba(213, 208, 208, 0.55);
	margin-left: 0.5rem;
}
.ct__featured-article__article-components-article img {
	max-width: 2.5rem;
	max-width: 2.5rem;
	padding: 0.2rem 0 0.2rem 0.2rem;
	object-fit: cover;
	position: relative;
	border-radius: 50%;
}
.ct__featured-article__article-components-article p {
	color: white;
	padding: 0.1rem 1rem;
	filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.45));
}
.ct__featured-article__article-desc {
	position: absolute;
	bottom: 0;
	right: 0;
	max-width: 30%;
	padding: 2rem 4rem 2rem 2rem;
}
.ct__featured-article__article-desc p {
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 13px;
	line-height: normal;
	color: #ffffff;
	padding-top: 2rem;
	filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.75));
}
.ct__featured-article__article-line {
	width: 30px;
	height: 1px;
	position: absolute;
	background-color: #ffffff;
}

@media screen and (max-width: 1240px) {
	.ct__featured-article__article-components h1 {
		font-weight: 900;
		font-size: 66px;
		line-height: 62px;
	}
	.ct__featured-article__article-desc {
		max-width: 35%;
	}
}
@media screen and (max-width: 1140px) {
	.ct__featured-article__article-components h1 {
		font-weight: 900;
		font-size: 64px;
		line-height: 62px;
	}
	.ct__featured-article__article-desc {
		max-width: 35%;
	}
}
@media screen and (max-width: 970px) {
	.ct__featured-article__article-components h1 {
		font-weight: 800;
		font-size: 54px;
		line-height: 52px;
	}
	.ct__featured-article__article-desc {
		max-width: 30%;
		padding: 2rem 1rem;
	}
}
@media screen and (max-width: 600px) {
	.ct__featured-article__article-components h1 {
		font-weight: 800;
		font-size: 30px;
		line-height: 35px;
		max-width: 80%;
		letter-spacing: 0px;
	}
	.ct__featured-article__article-desc {
		display: none;
	}
	.ct__featured-article__article-components {
		padding: 1rem;
	}
}
