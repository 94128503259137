.secondary-article__article {
	width: 100%;
	height: 50%;
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	position: relative;
	cursor: pointer;
}
.secondary-article__article-link {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	position: relative;
	cursor: pointer;
}

.secondary-article__article img {
	max-width: 100%;
	height: 100%;
	border-radius: 1rem;
	object-fit: cover;
	cursor: pointer;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.secondary-article__article-components {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 1.5rem;
	height: 100%;
	border-bottom-left-radius: 1rem;
	border-bottom-right-radius: 1rem;
}
.secondary-article__article-components p {
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 11.649px;
	line-height: 35px;
	color: #ffffff;
	filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}
.secondary-article__article-components h3 {
	font-weight: 800;
	font-size: 2rem;
	line-height: 30px;
	color: #ffffff;
	font-family: var(--font-family);
	margin-top: 0.5rem;
	cursor: pointer;
	filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.45));
}

@media screen and (max-width: 1240px) {
	.secondary-article__article-components h3 {
		font-size: 1.5rem;
	}
}
@media screen and (max-width: 970px) {
	.secondary-article__article-components h3 {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}
