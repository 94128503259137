.blog {
	display: grid;
	grid-template-columns: repeat(2, 50%);
	padding: 2rem;
	max-width: 1140px;
	margin: 0 auto;
	height: auto;
}

.blog-heading-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem;
	max-width: 1140px;
	margin: 0 auto;
}

.blog-heading-container h1 {
	font-family: var(--font-family);
	font-size: 40px;
	font-weight: 1300;
}

.blog-heading-container h4 {
	font-family: var(--font-family);
	font-size: 16px;
	font-weight: 300;
	color: var(--color-subtext);
}

@media screen and (max-width: 900px) {
	.blog {
		grid-template-columns: repeat(1, 100%);
		padding: 2rem 0.5rem;
	}

	.blog-article-component {
		background-color: rgb(237, 237, 237);
		border: none;
		outline: none;
		filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.45));
		position: relative;
	}

	.blog-article-component img {
		filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
		width: 100%;
		height: auto;
		object-fit: cover;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		cursor: pointer;
	}

	.blog-article-component-date {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
		position: absolute;
	}

	.blog-article-component-date p {
		color: white;
		font-family: var(--font-family);
		font-size: 16px;
		opacity: 0.7;
	}

	.blog-article-component-title {
		width: 100%;
		display: flex;
		padding: 1rem;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		flex-direction: column;
	}

	.blog-article-component-title h3 {
		font-size: 18px;
		line-height: 20px;
		text-decoration: none;
		color: black;
		font-family: var(--font-family);
		font-weight: 500;
		filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
		padding: 0.5rem 1rem;
	}

	.blog-article-component-title p {
		padding: 0.5rem 1rem;
		display: block;
		font-size: 16px;
		line-height: 20px;
		color: var(--color-subtext);
		font-family: var(--font-family);
	}
}
