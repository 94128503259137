.header {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	padding: 0.5rem;
}

.header-main {
	flex: 3;
	flex-wrap: wrap;
	border-radius: 1rem;
	min-height: 93vh;
}

.header-mobile {
	min-height: 93vh;
	max-height: 93vh;
	border-radius: 1rem;
	min-height: 93vh;
	display: block;
}

.header-secondary {
	flex: 1;
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	gap: 0.5rem;
	min-height: 90vh;
}

@media screen and (max-width: 800px) {
	.header-secondary {
		display: none;
	}
}

.slick-track {
	display: flex;
	max-height: 93vh;
}

.slick-slide {
	padding: 0 0.5rem;
}

.slick-slider {
	max-height: 93vh;
}

.slick-arrow {
	display: none;
}
