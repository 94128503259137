.custom-loader__container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 93vh;
}
.custom-loader {
	width: 70px;
	height: 70px;
	background:
		radial-gradient(farthest-side, #2b293b 90%, #0000) center/16px 16px,
		radial-gradient(farthest-side, #2f2e3d 90%, #0000) bottom/12px 12px;
	background-repeat: no-repeat;
	animation: s7 1s infinite linear;
	position: relative;
}
.custom-loader::before {
	content: '';
	position: absolute;
	width: 8px;
	height: 8px;
	inset: auto 0 16px;
	margin: auto;
	background: #e4e4ed;
	border-radius: 50%;
	transform-origin: 50% calc(100% + 10px);
	animation: inherit;
	animation-duration: 0.5s;
}

@keyframes s7 {
	100% {
		transform: rotate(1turn);
	}
}
