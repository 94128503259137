.contact {
	display: flex;
	position: relative;
	flex-direction: column;
	flex: 1;
	width: 100%;
	margin: 0 auto;
	max-width: 800px;
	padding: 0;
	overflow-x: hidden;
}

.contact-head-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem;
	max-width: 1140px;
	margin: 0 auto;
}

.contact-head-text h1 {
	font-family: var(--font-family);
	font-size: 40px;
	font-weight: 1300;
}

.contact-head-text h4 {
	font-family: var(--font-family);
	font-size: 16px;
	font-weight: 300;
	color: var(--color-subtext);
}

.contact-contact {
	display: flex;
	gap: 2.5rem;
	padding: 0 2rem;
	overflow: hidden;
	justify-content: center;
}

#message {
	margin-top: 5rem;
	margin-bottom: 5rem;
}

.contact-card {
	align-items: center;
	background: var(--bg-color);
	border: 2px solid black;
	border-radius: 1.5rem;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	margin: 1rem 0;
	min-width: 290px;
	padding: 0.7rem;
	transition: all 0.3s ease-in-out;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.contact-card:hover {
	color: var(--color-text-secondary);
	background-color: var(--color-bg-secondary);
}

.contact-card a {
	color: var(--gray-color);
	text-decoration: none;
	font-size: 0.8rem;
	line-height: 1.5;
	text-align: center;
	font-family: var(--font-family);
}

.contact-cards .contact-card p {
	font-weight: 500;
	font-family: var(--font-family);
}

.contact-cards .contact-card a {
	text-decoration: none;
	font-weight: 500;
	font-family: var(--font-family);
}

.contact-form {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
}
.contact-form input {
	width: 100%;
	flex-direction: column;
	margin: 0;
}

.contact-form div {
	width: 100%;
	border-radius: 1.5rem;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	background-color: transparent;
}

.contact-form div input {
	background-color: rgba(0, 0, 0, 0.1);
	width: 100%;
	padding: 1rem 2rem;
	border: none;
	border-radius: 1.5rem;
	font-family: var(--font-family);
	color: var(--secondary-color);
	outline: none;
	margin-bottom: 0.5rem;
}

.contact-form div input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 30px #d8d8d8 inset !important;
	-moz-box-shadow: 0 0 0 30px #d8d8d8 inset !important;
	-webkit-box-shadow: 0 0 0 30px #d8d8d8 inset !important;
	box-shadow: 0 0 0 30px #d8d8d8 inset !important;
}

.contact-form div textarea {
	width: 100%;
	padding: 1.5rem 2rem;
	border: none;
	border-radius: 1.5rem;
	font-family: var(--font-family);
	background-color: rgba(0, 0, 0, 0.1);
	color: var(--secondary-color);
	outline: none;
	height: 170px;
}

.contact-form button {
	padding: 0.7rem 2rem;
	border-radius: 1.5rem;
	border: 2px solid black;
	background-color: var(--bg-color);
	font-weight: 500;
	color: var(--white-color);
	outline: none;
	margin: 2rem 0 0rem 0;
	font-family: var(--font-family);
	transition: 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.contact-form button:hover {
	background-color: var(--color-bg-secondary);
	color: var(--color-text-secondary);
	transform: scale(1.05);
}

.contact-response {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5rem;
}

.contact-response {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5rem;
}

.contact-h3 {
	font-size: 35px;
	display: flex;
	text-align: center;
	font-family: var(--font-family);
}

@media screen and (max-width: 700px) {
	.contact-h3 {
		font-size: 30px;
	}
}

.contact-waves-div {
	position: relative;
	padding: 0;
	width: 100vw;
	height: 25vh;
}

.contact-waves__svg {
	width: 100vw;
	position: absolute;
	bottom: 3.9vh;
	left: 0;
}

.fill {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 4vh;
	background-color: #000000;
}

.contact-waves__twitter {
	color: #fff;
	z-index: 1;
	position: absolute;
	bottom: 0;
	right: 0;
	margin: 3rem 5rem;
}

.contact-waves__twitter-2 {
	color: #fff;
	z-index: 1;
	position: absolute;
	bottom: 0;
	right: 0;
	margin: 2.5rem 10rem;
}

.contact-waves__twitter-in {
	display: none;
	transition: 0.4s ease;
}

.contact-waves__twitter-in:hover {
	transform: scale(1.05);
}

@media screen and (min-width: 800px) {
	.contact-waves-div {
		display: none;
	}

	.contact-waves__twitter-in {
		display: block;
		margin: 2rem 1rem;
		color: #000000;
	}
}
@media screen and (max-width: 799px) {
	.hr-contact {
		display: none;
	}
}

@media screen and (max-width: 650px) {
	.contact-waves__twitter {
		margin: 1.5rem;
	}

	.contact-waves__twitter-2 {
		color: #fff;
		z-index: 1;
		position: absolute;
		bottom: 0;
		right: 0;
		margin: 1.5rem 7rem;
	}
}
@media screen and (max-width: 450px) {
	.contact-waves-div {
		position: relative;
		padding: 0;
		width: 100vw;
		height: 15vh;
	}
}

.hr-contact {
	margin: 2rem auto 0;
	width: 600px;
}

.contact-waves__rights {
	margin-bottom: 2rem;
}

.contact-waves__rights h6 {
	padding: 0.5rem;
	text-align: center;
	font-size: 14px;
	font-family: var(--font-family);
	text-decoration: none;
	text-transform: inherit;
	font-weight: 400;
}

.contact-waves__rights a {
	text-decoration: none;
	color: #000000;
	font-weight: 400;
}

.contact-waves__rights a:hover {
	text-decoration: underline;
}

.contact-waves {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.error-message {
	font-family: var(--font-family);
	text-align: center;
	background: #fff;
	background-color: #fff;
	font-weight: 700;
}
