/* ------------ full-article__header ------------- */
.full-article__header {
	position: relative;
	border-radius: 1rem;
	max-width: 800px;
	margin: 0 auto;
	padding: 0.5rem;
}

.full-article__header img {
	width: 100%;
	max-height: 60vh;
	object-fit: cover;
	border-radius: 1rem;
	user-select: none;
	filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.35));
}

.full-article__header-nav {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	padding: 2rem;
}

.full-article__header-nav__link {
	color: white;
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	margin: 2rem;
	z-index: 1;
}

.full-article__header-nav__link-arrow {
	color: white;
	cursor: pointer;
}

.full-article__header-nav__author {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-radius: 1.5rem;
	border: none;
	background-color: rgba(213, 208, 208, 0.55);
	margin-left: 0.5rem;
	cursor: pointer;
}

.full-article__header-nav__author.open {
	position: relative;
	z-index: 1;
	filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.9));
	min-height: 250px;
	-webkit-tap-highlight-color: transparent;
}

.full-article__header-nav__author img {
	max-width: 2.5rem;
	padding: 0.2rem 0 0.2rem 0.2rem;
	object-fit: cover;
	border-radius: 50%;
	-webkit-tap-highlight-color: transparent;
}

.full-article__header-nav__author p {
	color: #fff;
	padding: 0.1rem 1rem;
	font-family: var(--font-family);
	font-size: 12px;
	filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.45));
}

.expanded-author-details {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	-webkit-tap-highlight-color: transparent;
}

.full-article__header-nav__author.open img {
	max-width: 15rem;
	padding: 2rem;
}

.full-article__header-nav__author.open button {
	position: absolute;
	top: 0;
	right: 0;
	margin: 1rem;
	border: 1.5px solid #ffffff;
	border-radius: 50%;
	outline: none;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
	cursor: pointer;
	color: #ffffff;
	transition: 0.2s ease-in-out;
}

.full-article__header-nav__author.open button:hover {
	filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.45));
}

.expanded-article-details-data {
	font-family: var(--font-family);
}

.expanded-article-details-data h3 {
	filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.25));
	color: #ffffff;
	font-size: 22px;
	padding: 3rem 1rem 0 1rem;
	font-family: var(--font-family);
}

.expanded-article-details-data p {
	font-size: 16px;
	font-family: var(--font-family);
	padding: 1rem 4rem 3rem 1rem;
	color: #ffffff;
	filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.25));
}

@media screen and (max-width: 650px) {
	.full-article__header-nav__author.open {
		flex-direction: column;
		filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.9));
		background-color: rgba(213, 208, 208, 0.75);
	}

	.expanded-article-details-data h3 {
		font-size: 18px;
		text-align: center;
		padding: 1rem;
	}

	.full-article__header-nav__author.open p {
		font-size: 13px;
		text-align: center;
		padding: 0 2rem 2rem 2rem;
	}

	.full-article__header-nav__author.open img {
		padding: 0.5rem;
		max-width: 170px;
	}
}

@media screen and (max-width: 550px) {
	.full-article__header-nav__author img {
		padding: 0.2rem;
	}

	.full-article__header-nav__author p {
		display: none;
	}

	.full-article__header-nav__author.open p {
		display: block;
	}
}

.full-article__header-h1 {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 3rem 2rem;
	color: white;
}

.full-article__header-h1 h1 {
	font-size: 30px;
	font-family: var(--font-family);
	max-width: 95%;
	filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.5));
}

.full-article__header-data {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: 0.5rem;
	color: #808080;
}

.full-article__header-data p {
	font-family: var(--font-family);
	filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.8));
}

.full-article__header-data span {
	padding: 0 0.5rem;
}

@media screen and (max-width: 720px) {
	.full-article__header-h1 h1 {
		font-size: 20px;
	}

	.full-article__header-h1 {
		padding: 2rem;
	}

	.full-article__header-data {
		padding-top: 0.5rem;
		font-size: 14px;
	}
}

@media screen and (max-width: 400px) {
	.full-article__header-h1 h1 {
		font-size: 20px;
		max-width: 100%;
		line-height: 20px;
	}

	.full-article__header-h1 {
		padding: 2rem;
	}

	.full-article__header-data {
		padding-top: 0.5rem;
		font-size: 12px;
	}
}
@media screen and (max-width: 350px) {
	.full-article__header-h1 h1 {
		font-size: 16px;
		max-width: 100%;
		line-height: 18px;
	}

	.full-article__header-h1 {
		padding: 1.5rem;
	}

	.full-article__header-data {
		padding-top: 0.5rem;
		font-size: 12px;
	}
}

/* ----------- full-article__share --------------*/

.full-article__share {
	position: relative;
	max-width: 800px;
	margin: 0 auto;
	padding: 1rem;
	font-family: var(--font-family);
}

.full-article__share h3 {
	font-family: var(--font-family);
	text-align: center;
	font-size: 30px;
	font-weight: 700;
	letter-spacing: 1px;
}

.full-article__share-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.full-article__share-header__arrow-twisted {
	max-width: 120px;
	padding: 1rem;
}

.full-article__share-icons {
	display: flex;
	width: 100%;
	justify-content: space-around;
	align-items: center;
}

.full-article__share-icons a {
	color: #000000;
	text-decoration: none;
}

.full-article__share-icons svg {
	cursor: pointer;
	transition: 0.4s;
}

.full-article__share-icons svg:hover {
	transform: scale(1.05);
}

.full-article__share-icons img:hover {
	transform: scale(1.05);
}

.hr-full-article {
	margin: 2rem 0;
}

.hr-full-article-last {
	margin: 2rem 0 0 0;
}

@media screen and (max-width: 650px) {
	.full-article__share h3 {
		padding: 0rem;
		font-size: 20px;
	}

	.full-article__share-header__arrow-twisted {
		max-width: 100px;
		margin: 0;
		padding: 0.5rem;
	}

	.full-article__share-icons svg {
		max-width: 50px;
	}

	.hr-full-article {
		margin: 1rem 0;
	}

	.hr-full-article-last {
		margin: 1rem 0 0 0;
	}
}

/* ----------- full-article__featured --------------*/

.full-article__featured {
	position: relative;
	max-width: 800px;
	margin: 0 auto;
	padding: 1rem;
	font-family: var(--font-family);
}

.full-article__featured h3 {
	font-family: var(--font-family);
	text-align: center;
	font-size: 30px;
	font-weight: 700;
	letter-spacing: 1px;
	padding: 0 0 1rem 0;
}

.full-article__featured hr {
	max-width: 400px;
	margin: 1rem auto;
}

.full-article__featured-card {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.full-article__featured-card img {
	width: 40%;
	border-radius: 1rem;
	margin: 0.5rem;
}

.full-article__featured-card-components {
	display: flex;
	flex-direction: column;
}

.full-article__featured-card-components h4 {
	font-family: var(--font-family);
	font-size: 20px;
	padding: 1rem 1rem 0rem 1rem;
}

.full-article__featured-card-components p {
	font-family: var(--font-family);
	font-size: 15px;
	padding: 0.5rem 1rem;
}

.full-article__featured-card-components button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-decoration: none;
	color: #000000;
	background-color: #fff;
	border-radius: 1.5rem;
	border: 2px solid black;
	padding: 0.6rem 1.2rem;
	margin: 0.5rem 1rem;
	font-size: 15px;
	transition: 0.4s ease-in-out;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.full-article__featured-card-components button:hover {
	background-color: #000000;
	color: #fff;
}

.full-article__featured-card-components-link {
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}
.hr__full-article__featured-last {
	max-width: 800px;
	margin: 1rem auto;
}

@media screen and (max-width: 650px) {
	.full-article__featured h3 {
		font-size: 20px;
	}

	.full-article__featured-card-components h4 {
		font-size: 18px;
	}

	.full-article__featured-card-components p {
		font-family: var(--font-family);
		font-size: 14px;
		padding: 0.5rem 1rem;
	}

	.full-article__featured-card-components button {
		color: #000000;
		background-color: #fff;
		border-radius: 1.5rem;
		border: 2px solid black;
		padding: 0.6rem 1.2rem;
		margin: 0.5rem 1rem;
		font-size: 15px;
	}
}

@media screen and (max-width: 450px) {
	.full-article__featured-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.full-article__featured-card img {
		width: 100%;
	}
}

.full-article__featured-card-arrow {
	transition: 0.4s ease-in-out;
}

.full-article__featured-card-components-link button:hover .full-article__featured-card-arrow {
	margin-left: 0.5rem;
}

/* ------------ .full-article__footer -----------  */

.full-article__footer {
	max-width: 800px;
	margin: 0 auto;
	padding: 0 1rem;
}

.full-article__footer-rights {
	text-align: center;
	font-size: 14px;
	font-family: var(--font-family);
	padding: 1rem;
	margin-bottom: 1rem;
	font-weight: 400;
}

.xcorp {
	max-width: 90px;
	cursor: pointer;
	transition: 0.4s;
}

.linkedin {
	max-width: 85px;
	cursor: pointer;
	transition: 0.4s;
}

@media screen and (max-width: 650px) {
	.xcorp {
		max-width: 62px;
	}

	.linkedin {
		max-width: 53px;
	}
}

/* ----- BLOCK CONTENT CSS */

.full-article-block__content {
	position: relative;
	max-width: 800px;
	margin: 0 auto;
	padding: 1rem;
	font-family: var(--font-family);
}

.full-article-block__content h3 {
	font-size: 22px;
}

.full-article-block__content h4 {
	font-size: 20px;
}

.full-article-block__content a {
	text-decoration: none;
}

.full-article-block__content li {
	padding: 0.5rem 1rem;
}

.full-article-block__content ul {
	padding: 0.5rem 1rem;
}

.full-article-block__content ol {
	padding: 0.5rem 1rem;
}

.full-article-block__content blockquote {
	line-height: 25px;
	font-size: 14px;
	padding-bottom: 1rem;
}

.full-article-block__content p {
	padding: 0.5rem 0;
	line-height: 25px;
	font-size: 16px;
}

.full-article-block__content img {
	margin: 1rem 0 0.5rem 0;
	width: 100%;
	border-radius: 1rem;
	object-fit: cover;
	filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.35));
}
